import type { FormEvent } from 'react';

import { t } from '../../locale/texts';
import { dateRangeFor, durationFor, monthFor } from './buildParams';
import type { Month, Duration } from './DepartureByMonth';

type PrepackageSearchSubmitProps = {
    resultUrl: string;
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
    originAirport?: string;
    destinationId?: string;
    departureDate?: Date;
    returnDate?: Date;
    flexibleDeparture?: number;
    travelMonths?: Month[];
    duration?: Duration;
    searchType?: string;
    widget?: string;
};

export const PrepackageSearchSubmit = ({
    resultUrl,
    handleSubmit,
    duration,
    originAirport,
    destinationId,
    departureDate,
    flexibleDeparture,
    returnDate,
    travelMonths,
    searchType,
    widget,
}: PrepackageSearchSubmitProps) => (
    <form onSubmit={handleSubmit} action={resultUrl} method="GET">
        {originAirport ? <input type="hidden" name="fra" value={originAirport} /> : null}
        {destinationId ? <input type="hidden" name="til" value={destinationId} /> : null}
        {searchType === 'fixed' && departureDate && flexibleDeparture ? (
            <input type="hidden" name="avreise" value={dateRangeFor(departureDate, flexibleDeparture)} />
        ) : null}
        {searchType === 'fixed' && returnDate && flexibleDeparture ? (
            <input type="hidden" name="hjemreise" value={dateRangeFor(returnDate, flexibleDeparture)} />
        ) : null}
        {searchType === 'flexible' && duration ? <input type="hidden" name="reiselengde" value={durationFor(duration)} /> : null}
        {searchType === 'flexible'
            ? travelMonths?.map((month) => (
                  <input key={`travel-month${monthFor(month)}`} type="hidden" name="reiseperiode" value={monthFor(month)} />
              ))
            : null}

        {widget && (
            <>
                <input type="hidden" name="utm_source" value={widget} />
                <input type="hidden" name="utm_medium" value="widget" />
                <input type="hidden" name="utm_campaign" value="pakkereiser" />
            </>
        )}

        <button
            type="submit"
            className="button button--primary px-64 w-full max-w-full sm:w-auto sm:max-w-auto mt-24"
            data-testid="prepackageSearchButtonNew">
            {t('action.search')}
        </button>
    </form>
);
