import { t } from '../locale/texts';

type HeadingProps = {
    vertical: string;
    destination?: string;
    as?: string;
};

export const Heading = ({ vertical, destination, as: Tag = 'h1' }: HeadingProps) => (
    // @ts-expect-error TS-2322 Type is not assignable to type IntrinsicAttributes
    <Tag id="main-heading" className="text-16 sm:text-28 text-center my-32 mx-16" data-testid="main-heading">
        {`${t(`heading.${vertical}.prefix`)} ${destination ? t(`heading.${vertical}.preposition`, { destination }) : t('heading.postfix')}`}
    </Tag>
);
