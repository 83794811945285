import { trackEvent } from '@finn-no/pulse-sdk';
import { IconCalculator32, IconCharter32, IconChevronRight16, IconTag32 } from '@fabric-ds/icons/react';
import { Box } from '@fabric-ds/react';

import { t } from 'shared/locale/texts';

// Pulse tracker id: TR_ev10 and TR_ev11 (see tracking section in README.md)
const trackOpenVerticalClick = (vertical, path) =>
    trackEvent({
        type: 'Click',
        intent: 'Open',
        name: `Open ${vertical}`,
        object: {
            type: 'UIElement',
            elementType: 'Link',
        },
        target: {
            id: path,
            type: vertical === 'currency' ? 'Page' : 'Listing',
            name: `Go to ${vertical}`,
        },
        vertical: {
            name: 'travel',
            subVertical: vertical,
        },
    });

const renderLink = (vertical, path, icon) => (
    <div onClick={() => trackOpenVerticalClick(vertical, path)} className="flex gap-x-16 text-gray-700 other-verticals-link">
        <div className="pt-8">{icon}</div>
        <div>
            <h3 className="mb-0 text-16 inline-block align-middle" id={`${vertical}Link`}>
                <a href={path} data-testid={`link-${vertical}`} className="text-gray-700" aria-labelledby={`${vertical}Link`}>
                    {t(`verticals.${vertical}`)}
                </a>
            </h3>
            <IconChevronRight16 className="inline-block ml-16" />
            <p className="text-gray-600 mt-4">{t(`verticals.${vertical}_sub`)}</p>
        </div>
    </div>
);

export const OtherVerticals = () => (
    <Box neutral as="nav" className="my-32 p-32" data-testid="otherVerticals" aria-labelledby="otherVerticalsHeading">
        <h2 className="sr-only" id="otherVerticalsHeading">
            {t('verticals.title')}
        </h2>
        <div className="grid md:grid-cols-3 gap-24">
            {renderLink('currency', '/reise/valuta/', <IconCalculator32 />)}
            {renderLink('prepackage', '/reise/pakkereiser/resultat/', <IconCharter32 />)}
            {renderLink('last_minute', '/reise/restplasser/', <IconTag32 />)}
        </div>
    </Box>
);
