import { makeAction, makeReducer } from '@finn-no/redux-actions';

const actions = {};

actions.allFieldsChanged = makeAction('FLIGHT/ALL_FIELDS_CHANGED', (state, { payload }) => ({ ...state, ...payload }));

actions.setTripType = makeAction('FLIGHT/SET_TRIP_TYPE', 'tripType');

actions.setDates = makeAction('FLIGHT/SET_DATES', (state, { payload }) => ({
    ...state,
    departureDate: payload.startDate ?? null,
    returnDate: payload.endDate ?? null,
}));

actions.setCabinType = makeAction('FLIGHT/SET_CLASS_TYPE', 'cabinType');

actions.setOrigin = makeAction('FLIGHT/SET_ORIGIN', (state, { payload }) => ({
    ...state,
    origin: payload?.id ?? null,
    originName: payload?.text ?? null,
    originCity: payload?.city ?? null,
    originCountry: payload?.country ?? null,
}));

actions.setDestination = makeAction('FLIGHT/SET_DESTINATION', (state, { payload }) => ({
    ...state,
    destination: payload?.id ?? null,
    destinationName: payload?.text ?? null,
    destinationCity: payload?.city ?? null,
    destinationCountry: payload?.country ?? null,
}));

actions.setOrigin2 = makeAction('FLIGHT/SET_ORIGIN2', (state, { payload }) => ({
    ...state,
    origin2: payload?.id ?? null,
    originName2: payload?.text ?? null,
    originCity2: payload?.city ?? null,
    originCountry2: payload?.country ?? null,
}));

actions.setDestination2 = makeAction('FLIGHT/SET_DESTINATION2', (state, { payload }) => ({
    ...state,
    destination2: payload?.id ?? null,
    destinationName2: payload?.text ?? null,
    destinationCity2: payload?.city ?? null,
    destinationCountry2: payload?.country ?? null,
}));

actions.swapOriginDestination = makeAction('FLIGHT/SWAP_ORIGIN_DESTINATION', (state) => ({
    ...state,
    origin: state.destination ?? null,
    originName: state.destinationName ?? null,
    originCity: state.destinationCity ?? null,
    originCountry: state.destinationCountry ?? null,
    destination: state.origin ?? null,
    destinationName: state.originName ?? null,
    destinationCity: state.originCity ?? null,
    destinationCountry: state.originCountry ?? null,
}));

actions.swapOriginDestination2 = makeAction('FLIGHT/SWAP_ORIGIN_DESTINATION2', (state) => ({
    ...state,
    origin2: state.destination2 ?? null,
    originName2: state.destinationName2 ?? null,
    originCity2: state.destinationCity2 ?? null,
    originCountry2: state.destinationCountry2 ?? null,
    destination2: state.origin2 ?? null,
    destinationName2: state.originName2 ?? null,
    destinationCity2: state.originCity2 ?? null,
    destinationCountry2: state.originCountry2 ?? null,
}));

actions.setPassenger = makeAction('FLIGHT/SET_PASSENGER', (state, { payload }) => {
    const newState = { ...state, ...payload };
    const totalPassengers = newState.adults + newState.children + newState.infants;
    const invalidPassengerCount = totalPassengers > 9 || newState.adults < 1 || newState.children < 0;

    return invalidPassengerCount ? state : { ...newState };
});

actions.setOpenHotelSearch = makeAction('FLIGHT/SET_OPEN_HOTEL_SEARCH', 'openHotelSearch');

export default actions;
export const reducer = makeReducer(actions);
