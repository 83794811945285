import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { t } from '../../locale/texts';
import { safeFormatISODate } from '../../util/datetime-fns';

const Location = (props) => {
    const { type, locationId, locationLatitude, locationLongitude, locationName } = props;

    if (locationId) {
        return <input type="hidden" name={`${type}LocationId`} value={locationId} />;
    }
    if (locationLatitude && locationLongitude) {
        return (
            <>
                <input type="hidden" name={`${type}LocationLat`} value={locationLatitude} />
                <input type="hidden" name={`${type}LocationLng`} value={locationLongitude} />
                {locationName ? <input type="hidden" name={`${type}Name`} value={locationName} /> : null}
            </>
        );
    }
    return null;
};

Location.propTypes = {
    type: PropTypes.string,
    locationId: PropTypes.string,
    locationLatitude: PropTypes.string,
    locationLongitude: PropTypes.string,
    locationName: PropTypes.string,
};

export const CarSearchSubmit = ({
    handleSubmit,
    driverAgeIsStandard,
    driverAge,
    pickupDate,
    pickupTime,
    returnDate,
    returnTime,
    pickupLocationId,
    pickupLocationLatitude,
    pickupLocationLongitude,
    pickupLocation,
    returnLocationId,
    returnLocationLatitude,
    returnLocationLongitude,
    returnLocation,
    returnLocationIsSame,
    unleash,
    widget,
}) => {
    const router = useRouter();

    useEffect(() => {
        // TODO REIS-5444 use this when Next.js is upgraded to >10.0.5
        //if (!router.isReady) return;

        if (router.query?.o) {
            // This makes sure people do not bookmark URLs with origin parameters,
            // i.e. it replaces all parameters with `?bookmarked=true`
            window.history.replaceState(null, '', '?bookmarked=true');
        }
    }, [router.query]);

    const chooseClientId = () => {
        if (router.query?.o === 'car_frontpage') {
            return '584700'; // when coming here from the `Leiebil` link on the Car category page on FINN frontpage
        }
        if (unleash?.carRentalSale) {
            return router.query?.o === 'car_banner' ? '588678' : '879089'; // sale
        }
        return '301413'; // regular
    };

    return (
        <form onSubmit={handleSubmit} action="https://leiebil.finn.no/NO/book" method="GET">
            <input type="hidden" name="clientID" value={chooseClientId()} data-testid="clientIdInput" />
            <input type="hidden" name="currency" value="NOK" />
            <input type="hidden" name="residenceID" value="NO" />
            <input type="hidden" name="age" value={driverAgeIsStandard ? 30 : driverAge} />
            <input
                type="hidden"
                name="pickupDateTime"
                value={`${safeFormatISODate(pickupDate)}T${pickupTime}`}
                data-testid="pickupDateTimeInput"
            />
            <input
                type="hidden"
                name="returnDateTime"
                value={`${safeFormatISODate(returnDate)}T${returnTime}`}
                data-testid="returnDateTimeInput"
            />

            <Location
                type="pickup"
                locationId={pickupLocationId}
                locationLatitude={pickupLocationLatitude}
                locationLongitude={pickupLocationLongitude}
                locationName={pickupLocation}
            />
            {!returnLocationIsSame && (
                <Location
                    type="return"
                    locationId={returnLocationId}
                    locationLatitude={returnLocationLatitude}
                    locationLongitude={returnLocationLongitude}
                    locationName={returnLocation}
                />
            )}

            {widget && (
                <>
                    <input type="hidden" name="utm_source" value={widget} />
                    <input type="hidden" name="utm_medium" value="widget" />
                    <input type="hidden" name="utm_campaign" value="leiebil" />
                </>
            )}

            <button
                type="submit"
                className="button button--primary px-64 w-full max-w-full sm:w-auto sm:max-w-auto mt-24"
                data-testid="carRentalSearchButton">
                {t('action.search')}
            </button>
        </form>
    );
};

CarSearchSubmit.propTypes = {
    driverAgeIsStandard: PropTypes.bool,
    driverAge: PropTypes.string,
    pickupDate: PropTypes.instanceOf(Date),
    pickupTime: PropTypes.string,
    returnDate: PropTypes.instanceOf(Date),
    returnTime: PropTypes.string,
    pickupLocation: PropTypes.string,
    pickupLocationId: PropTypes.string,
    pickupLocationLatitude: PropTypes.string,
    pickupLocationLongitude: PropTypes.string,
    returnLocationIsSame: PropTypes.bool,
    returnLocation: PropTypes.string,
    returnLocationId: PropTypes.string,
    returnLocationLongitude: PropTypes.string,
    returnLocationLatitude: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    unleash: PropTypes.object,
    widget: PropTypes.string,
};
