import { t } from 'shared/locale/texts';

const breadcrumb = {
    flight: {
        name: t('verticals.flight'),
        url: 'https://finn.no/reise/',
    },
    hotel: {
        name: t('verticals.hotel'),
        url: 'https://finn.no/reise/hotell/',
    },
    hoteller: {
        name: t('verticals.hotel'),
        url: 'https://finn.no/reise/hotell',
    },
    carrental: {
        name: t('verticals.car'),
        url: 'https://finn.no/reise/leiebil/',
    },
    prepackage: {
        name: t('verticals.prepackage_dynamic'),
        url: 'https://finn.no/reise/pakkereiser/',
    },
    fhh: {
        name: t('verticals.fhh'),
        url: { finn: 'https://finn.no/reise/feriehus-hytteutleie/' },
    },
};

const ldJson = (verticalName, verticalUrl) =>
    JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                name: 'Finn',
                item: 'https://finn.no/',
            },
            {
                '@type': 'ListItem',
                position: 2,
                name: 'Reise',
                item: 'https://finn.no/reise/',
            },
            {
                '@type': 'ListItem',
                position: 3,
                name: verticalName,
                item: verticalUrl,
            },
        ],
    });

export const googleBreadcrumb = (vertical) => (
    <script
        type="application/ld+json"
        data-cy="breadcrumb-content"
        dangerouslySetInnerHTML={{
            __html: ldJson(breadcrumb[vertical].name, breadcrumb[vertical].url),
        }}
    />
);
