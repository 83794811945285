import { t } from '../../locale/texts';
import actions from './hotelActions';

import { Select } from '@fabric-ds/react';
import type { HotelRoom } from './HotelRooms';

interface Props {
    room: HotelRoom;
    roomIndex: number;
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    dispatch: (action: any) => void;
}

const childAges = (
    <>
        <option value="0">Under 1 år</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
    </>
);

export const ChildAges = ({ room, roomIndex, dispatch }: Props) => {
    const setChildAge = (childIndex: number, childAge: number): void => {
        const children = [...room.children];
        if (children.length > childIndex) {
            children[childIndex] = childAge;
        }
        dispatch(actions.setRooms({ index: roomIndex, adults: room.adults, children }));
    };

    return (
        <>
            {room.children.map((childAge: number, childIndex: number) => (
                <Select
                    className="mt-8"
                    // biome-ignore lint/suspicious/noArrayIndexKey: This was set before biome was added
                    key={`childAge-${roomIndex}-${childIndex}`}
                    id={`childAge-${roomIndex}-${childIndex}`}
                    data-testid={`childAge-${roomIndex}-${childIndex}`}
                    label={t('hotel.room.childAge', { number: childIndex + 1 })}
                    aria-label={t('hotel.room.childAge', { number: childIndex + 1 })}
                    value={`${childAge}`}
                    onChange={(e) => setChildAge(childIndex, ~~e.target.value)}>
                    {childAges}
                </Select>
            ))}
        </>
    );
};
