import { Card, Clickable } from '@fabric-ds/react';

import { thousandSeparated, validSeoPath } from '../util/formatting';
import { formatReadableDateShortNoWeekday, parseISO } from '../util/datetime-fns';
import type { SeoDestination, SeoText } from './interfaces';

type PopularCountriesCardProps = {
    destination: SeoDestination;
    cdnUrl: string;
    verticalUrl: string;
    lang: SeoText;
    id: string;
};

export const PopularCountriesCard = ({ destination, cdnUrl, verticalUrl, lang, id }: PopularCountriesCardProps) => (
    <Card className="m-16" data-testid="destination-card">
        <div aria-owns={`${id}-headingToDestination ${id}-title`} />
        <img
            src={`${cdnUrl}${destination.image}.jpg`}
            loading="lazy"
            alt={`${lang.headingToDestination} ${destination.city ?? destination.country}`}
            data-testid="card-image"
        />
        <div className="p-16">
            <p className="flex justify-between text-14 text-gray-500">
                <span id={`${id}-headingToDestination`}>{lang.headingToDestination}</span>
                {destination.priceData && (
                    <span>
                        {formatReadableDateShortNoWeekday(parseISO(destination.priceData.departureDate))}
                        {destination.priceData.returnDate &&
                            ` - ${formatReadableDateShortNoWeekday(parseISO(destination.priceData.returnDate))}`}
                    </span>
                )}
            </p>
            <h3 id={`${id}-title`} className="flex justify-between mb-0 text-16">
                <Clickable
                    href={`${verticalUrl}${validSeoPath(destination.country)}/${
                        destination.city ? `${validSeoPath(destination.city)}/` : ''
                    }`}
                    title={destination.city ?? destination.country}
                    className="text-gray-700">
                    {destination.city ?? destination.country}
                </Clickable>
                {((destination.price ?? 0) > 0 || destination.priceData) && (
                    <div>
                        <span className="text-14 text-normal">Fra </span>
                        <span>{`${thousandSeparated(destination.priceData?.price ?? destination.price)} kr`}</span>
                    </div>
                )}
            </h3>
        </div>
    </Card>
);
