import { t } from '../../locale/texts';
import actions from './prePackageActions';

import { RangeSliderFilter } from '../../filters/SelectRangeSliderFilter';
import { Dropdown } from '../common/Dropdown';
import { MonthCalendar } from './MonthCalendar';

export type Month = {
    month: number;
    year: number;
};
export type Duration = {
    from: number;
    to: number;
};
type DepartureByMonthProps = {
    travelMonths: Month[];
    duration: Duration;
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    dispatch: (action: any) => void;
};

export const DepartureByMonth = ({ travelMonths, duration, dispatch }: DepartureByMonthProps) => {
    const generateDateText = () => {
        const monthNames = [
            { full: 'januar', short: 'jan' },
            { full: 'februar', short: 'feb' },
            { full: 'mars', short: 'mar' },
            { full: 'april', short: 'apr' },
            { full: 'mai', short: 'mai' },
            { full: 'juni', short: 'jun' },
            { full: 'juli', short: 'jul' },
            { full: 'august', short: 'aug' },
            { full: 'september', short: 'sep' },
            { full: 'oktober', short: 'okt' },
            { full: 'november', short: 'nov' },
            { full: 'desember', short: 'des' },
        ];
        const daysText = `${duration.from} - ${duration.to} dager`;
        const months = travelMonths;
        if (months.length === 0) {
            return `${daysText} når som helst`;
        }
        if (months.length === 1) {
            return `${daysText} i ${monthNames[months[0].month - 1]?.full}`;
        }
        return `${daysText} i ${months.map((month) => monthNames[month.month - 1]?.short).join(', ')}`;
    };

    return (
        <Dropdown id="departureMonth" text={generateDateText()} label={t('prepackage.flexible.title')}>
            <h4 className="text-18">{t('prepackage.months.title')}</h4>
            <MonthCalendar
                firstMonth={new Date().getMonth()}
                firstYear={new Date().getFullYear()}
                size={12}
                initialValues={travelMonths}
                toggleMonth={(month: string): void => dispatch(actions.toggleTravelMonth(month))}
            />
            <h4 className="text-18">{t('prepackage.duration')}</h4>
            <RangeSliderFilter
                id="durationSlider"
                labels={['Fra', 'Til']}
                values={[duration.from, duration.to]}
                min={1}
                max={35}
                onChange={(value) => dispatch(actions.setDuration({ from: value[0], to: value[1] }))}
                renderValue={(value) => `${value} ${t('misc.day', { count: value })}`}
            />
        </Dropdown>
    );
};
