import { Button } from '@fabric-ds/react';

import { t } from '../../locale/texts';

import { Dropdown } from '../common/Dropdown';
import { Passengers } from '../common/Passengers';

export type PassengersAndCabinProps = {
    id: string;
    adults: number;
    childrenCount: number;
    infants: number;
    setPassenger: (value: Record<string, number>) => void;
    cabinType: string;
    setCabinType: (value: string) => void;
};

const cabinTypes = ['economy', 'premium', 'business', 'first', 'all'];
const allCabinTypes = 'economy,premium,business,first';

export const PassengersAndCabin = ({
    id,
    adults,
    childrenCount,
    infants,
    setPassenger,
    cabinType,
    setCabinType,
}: PassengersAndCabinProps) => {
    const selectedCabinType: string = cabinType === allCabinTypes ? 'all' : cabinType;

    const renderCabinTypes = () =>
        cabinTypes.map((cabin) => (
            <Button
                primary={selectedCabinType === cabin}
                secondary={selectedCabinType !== cabin}
                small
                key={`${cabin}Button`}
                data-testid={`cabin-${cabin}`}
                onClick={() => setCabinType(cabin === 'all' ? allCabinTypes : cabin)}
                aria-pressed={selectedCabinType === cabin}
                className="px-8 truncate">
                {t(`flight.cabinType.${cabin}`)}
            </Button>
        ));

    const displayText = `${
        t('flight.passengers.adult', { count: adults }) +
        (childrenCount + infants > 0 ? `, ${t('flight.passengers.child', { count: childrenCount + infants })}` : '')
    }, ${t(`flight.cabinType.${selectedCabinType}`)}`;

    return (
        <Dropdown id={id} label={t('flight.passengers.title')} text={displayText}>
            <h3 className="mb-8 text-16 font-bold">{t('flight.passengers.travellers')}</h3>
            <Passengers adults={adults} childrenCount={childrenCount} infants={infants} showInfants setPassenger={setPassenger} />
            <h3 className="mb-8 mt-16 text-16 font-bold">{t('flight.cabinType.title')}</h3>
            <div className="flex flex-wrap gap-8">{renderCabinTypes()}</div>
        </Dropdown>
    );
};
