import { makeAction, makeReducer } from '@finn-no/redux-actions';

const actions = {};

actions.allFieldsChanged = makeAction('FHH/ALL_FIELDS_CHANGED', (state, { payload }) => ({ ...state, ...payload }));

actions.setDestination = makeAction('FHH/SET_DESTINATION', (state, { payload: destination }) => ({
    ...state,
    destinationData: destination ?? null,
    destination: destination ? destination.text : '',
}));

actions.setDates = makeAction('FHH/SET_DATES', (state, { payload: { startDate, endDate } }) => ({
    ...state,
    startDate: startDate ?? null,
    endDate: endDate ?? null,
}));

actions.setNumOfBeds = makeAction('FHH/SET_NUM_OF_BEDS', (state, { payload: beds }) => ({
    ...state,
    numOfBeds: Number(beds),
}));

actions.setNumOfBedrooms = makeAction('FHH/SET_NUM_OF_BEDROOMS', (state, { payload: bedrooms }) => ({
    ...state,
    numOfBedrooms: Number(bedrooms),
}));

// The default export contains all actions
export default actions;

// The exported reducer handles actions created with plain action creators
export const reducer = makeReducer(actions);
