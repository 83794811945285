import texts from './texts/nb.json';

const parseTemplate = (template, data) => {
    if (!data) return template;

    return template.replace(/{{([^}]+)}}/g, (match) => {
        // biome-ignore lint/style/noParameterAssign: This was set before biome was added
        match = match.slice(2, -2);
        const val = data[match.trim()];
        return val ?? `{{${match}}}`;
    });
};

export const t = (key, data) =>
    parseTemplate(
        key.split('.').reduce((p, c, _i, a) => {
            if (data?.count > 1 && Object.keys(p).includes(`${c}_plural`)) {
                return p[`${c}_plural`];
            }
            if (Object.keys(p).includes(c)) {
                return p[c];
            }
            console.warn(`Missing texts key: ${key}`);
            a.splice(0); // halt iteration in order to only warn once
            return key;
        }, texts),
        data,
    );
