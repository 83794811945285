import { addDays, formatClientDate, subDays } from '../../util/datetime-fns';
import type { Month, Duration } from './DepartureByMonth';

export const dateRangeFor = (baseDate: Date, flexibility: number): string => {
    const startDate = subDays(flexibility, baseDate);
    const endDate = addDays(flexibility, baseDate);
    return `${formatClientDate(startDate)}-${formatClientDate(endDate)}`;
};

export const durationFor = (duration: Duration): string => `${duration.from}-${duration.to}`;

export const monthFor = (month: Month): string => `${`0${month.month}`.slice(-2)}.${month.year}`;

const buildFixedDepartureParams = (state): (string | null)[] => [
    state.departureDate ? `departureDate=${dateRangeFor(state.departureDate, state.flexibleDeparture)}` : null,
    state.returnDate ? `returnDate=${dateRangeFor(state.returnDate, state.flexibleDeparture)}` : null,
];

const buildFlexibleDepartureParams = (state): string[] => {
    const params = state.travelMonths?.map((month) => `travelMonths=${monthFor(month)}`) ?? [];
    if (state.duration) {
        params.push(`duration=${durationFor(state.duration)}`);
    }
    return params;
};

export const buildParams = (state): string => {
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    let params: any[] = [];
    if (state.originAirport) {
        params.push(`from=${state.originAirport}`);
    }
    if (state.destinationId) {
        params.push(`to=${state.destinationId}`);
    }
    if (state.searchType === 'flexible') {
        params = params.concat(buildFlexibleDepartureParams(state));
    } else {
        params = params.concat(buildFixedDepartureParams(state));
    }
    return params.filter((param) => param !== null).join('&');
};
