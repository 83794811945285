export type Vertical = 'flight' | 'hotel' | 'carrental' | 'prepackage' | 'fhh';

export interface VerticalConfig {
    vertical: Vertical;
    fullPath: string;
    tracking: string;
}

export const verticalConfig: Record<Vertical, VerticalConfig> = {
    flight: {
        vertical: 'flight',
        fullPath: '/reise/',
        tracking: 'vertical-flight',
    },
    hotel: {
        vertical: 'hotel',
        fullPath: '/reise/hotell/',
        tracking: 'vertical-hotel',
    },
    carrental: {
        vertical: 'carrental',
        fullPath: '/reise/leiebil/',
        tracking: 'vertical-carrental',
    },
    prepackage: {
        vertical: 'prepackage',
        fullPath: '/reise/pakkereiser/',
        tracking: 'vertical-prepackage',
    },
    fhh: {
        vertical: 'fhh',
        fullPath: '/reise/feriehus-hytteutleie/',
        tracking: 'vertical-fhh',
    },
};
