import { makeAction, makeReducer } from '@finn-no/redux-actions';

const actions = {};

actions.allFieldsChanged = makeAction('HOTEL/ALL_FIELDS_CHANGED', (state, { payload }) => ({ ...state, ...payload }));

actions.setDestination = makeAction('HOTEL/SET_DESTINATION', (state, { payload }) => ({
    ...state,
    destination: payload?.text || '',
    destinationContext: payload || null,
}));

actions.setDates = makeAction('HOTEL/SET_DATES', (state, { payload: { startDate, endDate } }) => ({
    ...state,
    startDate: startDate ?? null,
    endDate: endDate ?? null,
}));

actions.addRoom = makeAction('HOTEL/ADD_ROOM', (state) => {
    const rooms = [...state.rooms, { adults: 2, children: [] }];
    return { ...state, rooms };
});

actions.removeRoom = makeAction('HOTEL/REMOVE_ROOM', (state) => {
    if (state.rooms.length > 1) {
        const rooms = [...state.rooms];
        rooms.pop();
        return { ...state, rooms };
    }
    return state;
});

actions.removeRoomWithIndex = makeAction('HOTEL/REMOVE_ROOM_INDEX', (state, { payload: index }) => {
    const rooms = [...state.rooms];
    rooms.splice(index, 1);
    return { ...state, rooms };
});

actions.setRooms = makeAction('HOTEL/SET_ROOMS', (state, { payload: { index, ...data } }) => {
    const roomState = { ...state.rooms[index], ...data };

    if (roomState.adults <= 9 && roomState.children.length <= 4) {
        const rooms = [...state.rooms];
        rooms[index] = roomState;
        return { ...state, rooms };
    }
    return state;
});

export default actions;
export const reducer = makeReducer(actions);
