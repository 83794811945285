type PlusIconProps = {
    width: number;
    height: number;
    disabled?: boolean;
    className?: string;
};

export const PlusIcon = (props: PlusIconProps) => (
    <svg aria-hidden="true" width={props.width} height={props.height} className={props.className} viewBox="0 0 52 52">
        <g fill={props.disabled ? '#DFE4E8' : '#767676'}>
            <path
                d="M26 0C11.664 0 0 11.663 0 26s11.664 26 26 26 26-11.663 26-26S40.336 0 26 0zm0 50C12.767 50 2 39.233 2 26S12.767 2 26
        2s24 10.767 24 24-10.767 24-24 24z"
            />
            <path d="M38.5 25H27V14a1 1 0 1 0-2 0v11H13.5a1 1 0 1 0 0 2H25v12a1 1 0 1 0 2 0V27h11.5a1 1 0 1 0 0-2z" />
        </g>
    </svg>
);
