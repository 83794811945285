import type { FormEvent } from 'react';

import { t } from '../../locale/texts';
import type { AutocompleteOption } from '../common/Autocomplete';
import { formatISODate } from '../../util/datetime-fns';

type FhhSearchSubmitProps = {
    resultUrl: string;
    destinationData?: AutocompleteOption | null;
    numOfBedrooms?: number;
    numOfBeds?: number;
    startDate?: Date | null;
    endDate?: Date | null;
    handleSubmit?: (event: FormEvent<HTMLFormElement>) => void;
    widget?: string;
};

export const FhhSearchSubmit = ({
    destinationData,
    numOfBedrooms,
    numOfBeds,
    startDate,
    endDate,
    handleSubmit,
    resultUrl,
    widget,
}: FhhSearchSubmitProps) => (
    <form onSubmit={handleSubmit} action={resultUrl} method="GET" key="fhhSearchForm">
        {destinationData &&
            (destinationData.bounds ? (
                <>
                    <input type="hidden" name="lat_ne" value={destinationData.bounds.northeast_lat} />
                    <input type="hidden" name="lat_sw" value={destinationData.bounds.southwest_lat} />
                    <input type="hidden" name="lng_ne" value={destinationData.bounds.northeast_lng} />
                    <input type="hidden" name="lng_sw" value={destinationData.bounds.southwest_lng} />
                    <input type="hidden" name="country" value={destinationData.country} />
                    <input type="hidden" name="city" value={destinationData.city ? destinationData.city : ''} />
                </>
            ) : (
                <input type="hidden" name="destination" value={destinationData.text} />
            ))}

        <input type="hidden" name="no_of_bedrooms_from" value={numOfBedrooms ?? 1} />
        <input type="hidden" name="no_of_beds_from" value={numOfBeds ?? 1} />

        {startDate && <input type="hidden" name="booking_from" value={formatISODate(startDate)} />}
        {endDate && <input type="hidden" name="booking_to" value={formatISODate(endDate)} />}

        {widget && (
            <>
                <input type="hidden" name="utm_source" value={widget} />
                <input type="hidden" name="utm_medium" value="widget" />
                <input type="hidden" name="utm_campaign" value="feriehus" />
            </>
        )}

        <button
            type="submit"
            data-testid="fhhSearchButton"
            className="button button--primary px-64 w-full max-w-full sm:w-auto sm:max-w-auto mt-24">
            {t('action.search')}
        </button>
    </form>
);
