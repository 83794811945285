import { addDays, differenceInCalendarDays, isFuture, max, safeParseISO } from '../../util/datetime-fns';
import { sanitize } from '../../util/sanitize';

import { flightDefaultState } from '../flight/flightDefaultState';
import { hotelDefaultState } from '../hotel/hotelDefaultState';
import { carDefaultState } from '../car/carDefaultState';
import { ppDefaultState } from '../prepackage/prePackageDefaultState';
import { fhhDefaultState } from '../fhh/fhhDefaultState';

const FLIGHT_SEARCH_FORM_STORAGE_KEY = 'flight_search_form';
const HOTEL_SEARCH_FORM_STORAGE_KEY = 'hotel_search_form';
const CAR_SEARCH_FORM_STORAGE_KEY = 'carrental_search_form';
const PREPACKAGE_SEARCH_FORM_STORAGE_KEY = 'prepackage_search_form';
const FHH_SEARCH_FORM_STORAGE_KEY = 'fhh_search_form';

export const getLastSearch = (key: string) => JSON.parse(window.localStorage.getItem(key) ?? '{}') || {};

// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
const withValidDates = (data: Record<string, any>, startDateKey = 'departureDate', endDateKey = 'returnDate', daysAhead = 1) => {
    if (!data) return {};

    const startDate = safeParseISO(data[startDateKey]);
    const endDate = safeParseISO(data[endDateKey]);
    const millisSinceEpoch = Date.now();

    return {
        ...data,
        [startDateKey]: startDate ? max([startDate, addDays(daysAhead, millisSinceEpoch)]) : null,
        [endDateKey]: endDate ? max([endDate, addDays(1, startDate ?? millisSinceEpoch), addDays(daysAhead + 1, millisSinceEpoch)]) : null,
    };
};

export const getLastSearchFlight = () => {
    const lastSearch = withValidDates(getLastSearch(FLIGHT_SEARCH_FORM_STORAGE_KEY));

    const adults = Math.min(lastSearch?.adults || 1, 9);
    const children = Math.min(lastSearch?.children || 0, 9 - adults);
    const infants = Math.min(lastSearch?.infants || 0, 9 - adults - children);

    const origin = lastSearch.origin ? {} : { origin: null, originCity: null, originCountry: null, originName: null };
    const destination = lastSearch.destination
        ? {}
        : { destination: null, destinationCity: null, destinationCountry: null, destinationName: null };

    // Reset tripType to roundtrip because Tabs component doesn't support changing tab after mount
    return { ...lastSearch, adults, children, infants, ...origin, ...destination, tripType: 'roundtrip' };
};

export const getLastSearchHotel = () => {
    const lastSearch = withValidDates(getLastSearch(HOTEL_SEARCH_FORM_STORAGE_KEY), 'startDate', 'endDate', 0);

    return {
        ...lastSearch,
        endDate:
            lastSearch.startDate && lastSearch.endDate && differenceInCalendarDays(lastSearch.startDate, lastSearch.endDate) > 30
                ? addDays(30, lastSearch.startDate)
                : lastSearch.endDate,
        rooms: lastSearch.rooms?.map((room) => ({
            ...room,
            children: Array.isArray(room.children) ? room.children : Array(room.children).fill(0),
        })) ?? [{ adults: 2, children: [] }],
        destination: lastSearch.destinationContext?.key ? lastSearch?.destination : undefined,
        destinationContext: lastSearch.destinationContext?.key ? lastSearch.destinationContext : undefined,
    };
};
export const getLastSearchCar = () => withValidDates(getLastSearch(CAR_SEARCH_FORM_STORAGE_KEY), 'pickupDate', 'returnDate');
export const getLastSearchPrepackage = () => {
    const lastSearch = withValidDates(getLastSearch(PREPACKAGE_SEARCH_FORM_STORAGE_KEY));
    const now = new Date();

    return {
        ...lastSearch,
        travelMonths:
            lastSearch.travelMonths?.filter(
                (monthData) =>
                    (monthData.month > now.getMonth() && monthData.year >= now.getFullYear()) || monthData.year > now.getFullYear(),
            ) ?? [],
    };
};

// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
const withValidDatesFhh = (data: Record<string, any>) => {
    if (!data) return {};

    const startDate = safeParseISO(data.startDate);
    const endDate = safeParseISO(data.endDate);

    return {
        ...data,
        startDate: startDate && isFuture(startDate) ? startDate : null,
        endDate: endDate && isFuture(endDate) ? endDate : null,
    };
};

export const getLastSearchFhh = () => withValidDatesFhh(getLastSearch(FHH_SEARCH_FORM_STORAGE_KEY));

const storeLastSearch = (key, state, defaultState) => {
    window.localStorage.setItem(key, JSON.stringify(sanitize(state, Object.keys(defaultState))));
};

export const storeLastSearchFlight = (state) => storeLastSearch(FLIGHT_SEARCH_FORM_STORAGE_KEY, state, flightDefaultState);
export const storeLastSearchHotel = (state) => storeLastSearch(HOTEL_SEARCH_FORM_STORAGE_KEY, state, hotelDefaultState);
export const storeLastSearchCar = (state) => storeLastSearch(CAR_SEARCH_FORM_STORAGE_KEY, state, carDefaultState);
export const storeLastSearchPrepackage = (state) => storeLastSearch(PREPACKAGE_SEARCH_FORM_STORAGE_KEY, state, ppDefaultState);
export const storeLastSearchFhh = (state) => storeLastSearch(FHH_SEARCH_FORM_STORAGE_KEY, state, fhhDefaultState);
