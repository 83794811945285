import Script from 'next/script';

declare global {
    interface Window {
        hj: (method: string, id: string | null, experiments: Record<string, string>) => void;
        travel_experiments?: Record<string, string>;
    }
}

const hjSettings = {
    hjid: 1666135,
    hjsv: 6,
};

const hotjarSetup = `window.hj=window.hj||function(){(window.hj.q=window.hj.q||[]).push(arguments);}
window._hjSettings=${JSON.stringify(hjSettings)};`;

export const Hotjar = ({ enabled, experiments }: { enabled: boolean; experiments?: Record<string, string> }) =>
    enabled ? (
        <>
            <script
                id="experiments-active"
                dangerouslySetInnerHTML={{ __html: `window.travel_experiments=${JSON.stringify(experiments)}` }}
            />
            <script id="hotjar-cfg" dangerouslySetInnerHTML={{ __html: hotjarSetup }} />
            <Script
                id="hotjar-js"
                src={`https://static.hotjar.com/c/hotjar-${hjSettings.hjid}.js?sv=${hjSettings.hjsv}`}
                onLoad={() => {
                    console.log('Hotjar: loaded');
                    if (window.travel_experiments) {
                        console.log('Hotjar: adding experiments as user attributes', experiments);
                        // No user id available here, so set it to null
                        // By adding the experiments here, we can use the Hotjar console to filter recordings based on the experiment
                        window.hj?.('identify', null, window.travel_experiments);
                    }
                }}
                crossOrigin=""
            />
        </>
    ) : null;
