const REFERRAL_STORAGE_KEY = 'accommodation-referral';
const GCLID_URL_KEY = 'gclid';
const LABEL_URL_KEY = 'label';

const timeInSeconds = (): number => Math.floor(Date.now() / 1000);

const createStoredId = (referral): string => {
    const thirtyDaysAheadInSeconds = timeInSeconds() + 60 * 60 * 24 * 30;
    const json = JSON.stringify({ referral, expires: thirtyDaysAheadInSeconds });
    return encodeURIComponent(json);
};

const addToLocalStorage = (urlKey: string): void => {
    try {
        const id = window.location.search
            ?.substr(1)
            .split('&')
            .filter((s) => s.startsWith(`${urlKey}=`))
            .map((s) => s.split('=')[1])[0];
        if (id) {
            window.localStorage.setItem(REFERRAL_STORAGE_KEY, createStoredId(id));
        }
    } catch (e) {
        // do nothing
    }
};

export const addGclidToLocalStorage = (): void => addToLocalStorage(GCLID_URL_KEY);

export const addLabelToLocalStorage = (): void => addToLocalStorage(LABEL_URL_KEY);
