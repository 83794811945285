import { Fragment } from 'react';
import { t } from '../../locale/texts';
import actions from './hotelActions';

import { Passengers } from '../common/Passengers';
import { ChildAges } from './ChildAges';

export interface HotelRoom {
    adults: number;
    children: number[];
}

type HotelRoomsProps = {
    rooms: HotelRoom[];
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    dispatch: (action: any) => void;
};

const convertPassengersToRooms = (room: HotelRoom, passengers: Record<string, number>): HotelRoom => {
    const children = passengers.children || passengers.children === 0 ? new Array(passengers.children).fill(0) : [...room.children];
    room.children.forEach((childAge, childIndex) => {
        if (children.length > childIndex) {
            children[childIndex] = childAge;
        }
    });
    return {
        adults: passengers.adults ?? room.adults,
        children,
    };
};
export const HotelRooms = ({ rooms, dispatch }: HotelRoomsProps) => {
    const renderRooms = () =>
        rooms.map((room: HotelRoom, roomIndex: number) => (
            // biome-ignore lint/suspicious/noArrayIndexKey: This was set before biome was added
            <Fragment key={`hotelRoom${roomIndex}`}>
                <div data-testid={`hotel-room-${roomIndex}`} className={`font-bold text-18 ${roomIndex > 0 ? 'mt-16' : ''}`}>
                    {t('hotel.room.main', { count: roomIndex + 1 })}
                </div>
                <Passengers
                    adults={room.adults}
                    childrenCount={room.children.length}
                    maxChildren={4}
                    setPassenger={(data) => dispatch(actions.setRooms({ index: roomIndex, ...convertPassengersToRooms(room, data) }))}
                    index={roomIndex}
                />
                <ChildAges room={room} roomIndex={roomIndex} dispatch={dispatch} />
                {room.adults + room.children.length === 0 ? (
                    <div className="text-14 text-red-600 pl-12">
                        {t('hotel.room.warning')}{' '}
                        <button
                            type="button"
                            data-testid={`delete-room-without-traveller-${roomIndex}`}
                            className="button button--link mb-2"
                            onClick={() => dispatch(actions.removeRoomWithIndex(roomIndex))}>
                            <span className="text-14">{t('hotel.room.warningButton')}</span>
                        </button>
                        .
                    </div>
                ) : null}
            </Fragment>
        ));

    return (
        <>
            {renderRooms()}
            <div className="mt-32 flex justify-between">
                <button
                    type="button"
                    data-testid="add-room"
                    className="button button--utility"
                    onClick={() => dispatch(actions.addRoom(rooms))}>
                    {t('hotel.room.add')}
                </button>
                {rooms.length > 1 ? (
                    <button
                        type="button"
                        data-testid="remove-room"
                        className="button button--utility"
                        onClick={() => dispatch(actions.removeRoom(rooms))}>
                        {t('hotel.room.remove')}
                    </button>
                ) : null}
            </div>
        </>
    );
};
