import { formatISODate, isDate, safeParseISO } from './datetime-fns';

export const sanitizeInput = (value: string | number | boolean | null | undefined): string | undefined =>
    value ? encodeURIComponent(value) : undefined;

export const sanitizeAlphanumeric = (value: string | null | undefined) => (value ? value.replace(/[<>'"`;[\](){}-]/g, '') : value);

export const sanitize = (
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    obj: Record<string, any>,
    validKeys: string[],
    dateKeys = ['departureDate', 'returnDate', 'startDate', 'endDate', 'pickupDate'],
) => {
    if (typeof obj !== 'object') {
        throw new Error('Sanitize requires a valid object');
    }

    if (!Array.isArray(validKeys)) {
        throw new Error('Sanitize requires an array of valid key strings');
    }

    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    return Object.keys(obj).reduce((acc: Record<string, any>, key: string) => {
        if (validKeys.includes(key)) {
            if (dateKeys.includes(key) && obj[key] !== null) {
                acc[key] = formatISODate(isDate(obj[key]) ? obj[key] : safeParseISO(obj[key]));
            } else {
                acc[key] = obj[key];
            }
        }
        return acc;
    }, {});
};
