export const ppDefaultState = {
    searchType: 'fixed',
    originAirport: '',
    destination: '',
    destinationId: undefined,
    departureDate: null,
    returnDate: null,
    flexibleDeparture: 3,
    duration: {
        from: 5,
        to: 15,
    },
    facetData: {},
    travelMonths: [],
};
