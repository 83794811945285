import { useState } from 'react';
import type { Month } from './DepartureByMonth';

type MonthCalendarProps = {
    firstYear: number;
    firstMonth: number;
    size: number;
    initialValues: Month[];
    toggleMonth: (month) => void;
};

const monthNames = ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'];

const isSelected = (selectedMonths: Month[], month: number, year: number): boolean =>
    selectedMonths.findIndex((e) => e.month === month && e.year === year) >= 0;

const yearObject = (year: number, startMonth: number, size: number, initialValues: Month[]) => {
    const calendarYear = {};
    Array.from(Array(size).keys()).forEach((index) => {
        const monthNumber = index + startMonth + 1;
        calendarYear[monthNumber] = {
            year,
            month: monthNumber,
            selected: isSelected(initialValues, monthNumber, year),
        };
    });
    return calendarYear;
};

export const generateCalendar = (firstYear: number, firstMonth: number, size: number, initialValues: Month[]) => {
    const calendar = {};
    let monthsLeft = size;
    let currentYear = firstYear;
    let currentMonth = firstMonth;

    while (monthsLeft > 0) {
        const monthsToAdd = Math.min(12, monthsLeft) - currentMonth;
        calendar[currentYear] = yearObject(currentYear++, currentMonth, monthsToAdd, initialValues);
        monthsLeft -= monthsToAdd;
        currentMonth = 0;
    }
    return calendar;
};

export const MonthCalendar = ({ firstYear, firstMonth, size, initialValues, toggleMonth }: MonthCalendarProps) => {
    const [calendar, setCalendar] = useState(generateCalendar(firstYear, firstMonth, size, initialValues));

    const monthClicked = (month) => {
        toggleMonth(month);
        calendar[month.year][month.month].selected = !month.selected;
        setCalendar(calendar);
    };

    const renderMonth = (monthObject) => {
        const { month, year, selected } = monthObject;
        return (
            <button
                type="button"
                key={`month-button-${year}-${month}`}
                data-testid={`month-button-${year}-${month}`}
                onClick={() => monthClicked({ year, month, selected })}
                className={`button ${selected ? 'button--primary' : 'button--tertiary'} button--small whitespace-nowrap my-4`}>
                {monthNames[month - 1]}
            </button>
        );
    };

    const renderMonths = (year) => (
        <div key={`year-container-${year}`} className="mb-12">
            <h5 key={`year-title-${year}`} data-testid={`year-title-${year}`} className="text-gray-400 text-center">
                {year}
            </h5>
            <div key={`month-container-${year}`} className="flex flex-wrap justify-between">
                {Object.keys(calendar[year]).map((month) => renderMonth(calendar[year][Number.parseInt(month)]))}
            </div>
        </div>
    );

    return <>{Object.keys(calendar).map((year) => renderMonths(Number.parseInt(year)))}</>;
};
