import { memo } from 'react';
import { Select } from '@fabric-ds/react';

type RangeSliderFilterProps = {
    id: string;
    labels: [string, string];
    values: [number, number];
    min: number;
    max: number;
    step?: number;
    onChange: (value: [number, number]) => void;
    renderValue?: (value: number) => string;
};

// TEMPORARY range "slider" (select boxes), until Fabric reimplements a range slider
export const RangeSliderFilter = memo(({ id, labels, values, min, max, step = 1, onChange, renderValue }: RangeSliderFilterProps) => {
    const fromOptions: number[] = [];
    for (let i = min; i < Math.min(max, values[1]); i = i + step) {
        fromOptions.push(i);
    }
    const toOptions: number[] = [];
    for (let j = Math.max(min, values[0]); j <= max; j = j + step) {
        toOptions.push(j);
    }

    return (
        <div data-testid={id} data-filter-type="slider" className="flex gap-8">
            <Select
                id={`${id}From`}
                data-testid={`${id}From`}
                label={labels[0]}
                aria-label={labels[0]}
                value={values[0]?.toString()}
                onChange={(e) => onChange([+e.target.value, values[1]])}
                className="flex-1">
                {fromOptions.map((val) => (
                    <option key={`${id}From${val}`} value={val}>
                        {renderValue ? renderValue(val) : val}
                    </option>
                ))}
            </Select>
            <Select
                id={`${id}To`}
                data-testid={`${id}To`}
                label={labels[1]}
                aria-label={labels[1]}
                value={values[1]?.toString()}
                onChange={(e) => onChange([values[0], +e.target.value])}
                className="flex-1">
                {toOptions.map((val) => (
                    <option key={`${id}To${val}`} value={val}>
                        {renderValue ? renderValue(val) : val}
                    </option>
                ))}
            </Select>
        </div>
    );
});

export const DummyRangeSliderFilter = memo(() => <div />);

RangeSliderFilter.displayName = 'RangeSliderFilter';
DummyRangeSliderFilter.displayName = 'DummyRangeSliderFilter';
