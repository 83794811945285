import { PlusIcon } from '../../icons/PlusIcon';
import { MinusIcon } from '../../icons/MinusIcon';

export const INCREASE_FILTER = 'add';
export const DECREASE_FILTER = 'decrease';

export const BEDROOM_KEY = 'no_of_bedrooms_from';
export const BED_KEY = 'no_of_beds_from';

type OnChangeProps = {
    key: typeof BEDROOM_KEY | typeof BED_KEY;
    value?: string | number;
    operation?: typeof INCREASE_FILTER | typeof DECREASE_FILTER;
};
type BedroomFilterProps = {
    filters: Record<string, number>;
    onFilterChanged: (val: Record<string, number>) => void;
};

export const BedroomFilter = ({ filters, onFilterChanged }: BedroomFilterProps) => {
    const onChange = ({ key, value, operation }: OnChangeProps): void => {
        // Convert to number to prevent 1+2=12
        const currentValue = Number(filters[key]) || 0;
        let newValue: number;

        if (operation === INCREASE_FILTER) {
            newValue = currentValue + 1;
        } else if (operation === DECREASE_FILTER) {
            newValue = Math.max(0, currentValue - 1);
        } else {
            const tmpValue = Number(value);

            if (Number.isNaN(tmpValue)) {
                newValue = 0;
            } else {
                newValue = Math.max(0, tmpValue);
            }
        }

        onFilterChanged({ [key]: newValue });
    };

    const getValue = (key: typeof BEDROOM_KEY | typeof BED_KEY): number | string => ((filters[key] || 0) === 0 ? '' : filters[key]);

    const isDisabled = (key: typeof BEDROOM_KEY | typeof BED_KEY): boolean => (filters[key] ?? 0) === 0;

    return (
        <>
            <div className="grid grid-cols-2 input input--text items-center" data-testid="bedroomFilter">
                <div>
                    <span id="numBedrooms">Soverom</span>
                    <div className="text-12 text-gray-500">Minimum antall</div>
                </div>
                <div className="grid grid-cols-3 gap-8 place-items-center">
                    <button
                        type="button"
                        onClick={() => onChange({ operation: DECREASE_FILTER, key: BEDROOM_KEY })}
                        aria-label="Fjern ett soverom"
                        disabled={isDisabled(BEDROOM_KEY)}
                        tabIndex={-1}
                        data-testid="removeBedroomButton">
                        <MinusIcon width={28} height={28} disabled={isDisabled(BEDROOM_KEY)} />
                    </button>
                    <input
                        type="text"
                        className="text-center"
                        data-testid="setBedroomInput"
                        onChange={({ target: { value } }) => onChange({ value, key: BEDROOM_KEY })}
                        onFocus={(event) => event.target.select()}
                        value={getValue(BEDROOM_KEY)}
                        placeholder="Alle"
                        aria-labelledby="numBedrooms"
                        style={{ paddingLeft: 2, paddingRight: 2 }}
                    />
                    <button
                        type="button"
                        onClick={() => onChange({ operation: INCREASE_FILTER, key: BEDROOM_KEY })}
                        aria-label="Legg til ett soverom"
                        tabIndex={-1}
                        data-testid="addBedroomButton">
                        <PlusIcon width={28} height={28} />
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-2 input input--text items-center mt-8" data-testid="bedFilter">
                <div>
                    <span id="numBeds">Sengeplasser</span>
                    <div className="text-12 text-gray-500">Minimum antall</div>
                </div>
                <div className="grid grid-cols-3 gap-8 place-items-center">
                    <button
                        type="button"
                        onClick={() => onChange({ operation: DECREASE_FILTER, key: BED_KEY })}
                        aria-label="Fjern en sengeplass"
                        disabled={isDisabled(BED_KEY)}
                        tabIndex={-1}
                        data-testid="removeBedButton">
                        <MinusIcon width={28} height={28} disabled={isDisabled(BED_KEY)} />
                    </button>
                    <input
                        type="text"
                        className="text-center"
                        data-testid="setBedInput"
                        onChange={({ target: { value } }) => onChange({ value, key: BED_KEY })}
                        onFocus={(event) => event.target.select()}
                        value={getValue(BED_KEY)}
                        placeholder="Alle"
                        aria-labelledby="numBeds"
                        style={{ paddingLeft: 2, paddingRight: 2 }}
                    />
                    <button
                        type="button"
                        onClick={() => onChange({ operation: INCREASE_FILTER, key: BED_KEY })}
                        aria-label="Legg til en sengeplass"
                        tabIndex={-1}
                        data-testid="addBedButton">
                        <PlusIcon width={28} height={28} />
                    </button>
                </div>
            </div>
        </>
    );
};
