import { makeAction, makeReducer } from '@finn-no/redux-actions';

const actions = {};

actions.allFieldsChanged = makeAction('CAR/ALL_FIELDS_CHANGED', (state, { payload }) => ({ ...state, ...payload }));

// Pickup location
actions.setPickupLocationParams = makeAction('CAR/SET_PICKUP_PARAMS', (state, { payload }) => ({
    ...state,
    pickupLocation: payload?.text ? payload.text : '',
    pickupLocationId: payload?.code ? payload.code : null,
    pickupLocationLatitude: payload?.latitude ? payload.latitude : null,
    pickupLocationLongitude: payload?.longitude ? payload.longitude : null,
}));

// Return location
actions.setReturnLocation = makeAction('CAR/SET_RETURN_LOCATION', (state, { payload }) => ({
    ...state,
    returnLocation: payload?.text ? payload.text : '',
    returnLocationId: payload?.code ? payload.code : null,
    returnLocationLatitude: payload?.latitude ? payload.latitude : null,
    returnLocationLongitude: payload?.longitude ? payload.longitude : null,
}));
actions.setReturnLocationIsSame = makeAction('CAR/SET_RETURN_LOCATION_IS_SAME', 'returnLocationIsSame');

// Date/time
actions.setDates = makeAction('CAR/SET_DATES', (state, { payload }) => ({
    ...state,
    pickupDate: payload.startDate ?? null,
    returnDate: payload.endDate ?? null,
}));
actions.setPickupTime = makeAction('CAR/SET_PICKUP_TIME', 'pickupTime');
actions.setReturnTime = makeAction('CAR/SET_RETURN_TIME', 'returnTime');

// Driver age
actions.setDriverAge = makeAction('CAR/SET_DRIVER_AGE', 'driverAge');
actions.setDriverAgeIsStandard = makeAction('CAR/SET_DRIVER_AGE_IS_STANDARD', 'driverAgeIsStandard');

actions.showCalendar = makeAction('CALENDAR/SHOW_CALENDAR', (state, { payload: { calendarId } }) => ({ ...state, calendar: calendarId }));
actions.hideCalendar = makeAction('CALENDAR/HIDE_CALENDAR', (state) => ({ ...state, calendar: undefined }));

/*
 * Exports
 */
// The default export contains all actions
export default actions;

// The exported reducer handles actions created with plain action creators
export const reducer = makeReducer(actions);
