export type VerticalConfig = {
    vertical: 'flight' | 'hotel' | 'carrental' | 'prepackage' | 'fhh';
    path: string;
    canonical: string;
};

export const verticalConfig: Record<string, VerticalConfig> = {
    flight: {
        vertical: 'flight',
        path: '',
        canonical: 'https://www.finn.no/reise/',
    },
    hotel: {
        vertical: 'hotel',
        path: 'hotell',
        canonical: 'https://www.finn.no/reise/hotell/',
    },
    carrental: {
        vertical: 'carrental',
        path: 'leiebil',
        canonical: 'https://www.finn.no/reise/leiebil/',
    },
    prepackage: {
        vertical: 'prepackage',
        path: 'pakkereiser',
        canonical: 'https://www.finn.no/reise/pakkereiser/',
    },
    fhh: {
        vertical: 'fhh',
        path: 'feriehus-hytteutleie',
        canonical: 'https://www.finn.no/reise/feriehus-hytteutleie/',
    },
};
