export const flightDefaultState = {
    tripType: 'roundtrip',
    departureDate: null,
    returnDate: null,
    origin: null,
    originCity: null,
    originCountry: null,
    originName: null,
    destination: null,
    destinationCity: null,
    destinationCountry: null,
    destinationName: null,
    origin2: null,
    originCity2: null,
    originCountry2: null,
    originName2: null,
    destination2: null,
    destinationCity2: null,
    destinationCountry2: null,
    destinationName2: null,
    adults: 1,
    children: 0,
    infants: 0,
    cabinType: 'economy',
    openHotelSearch: true,
};
