import type { MouseEvent, ReactElement } from 'react';
import { Tab, Tabs } from '@fabric-ds/react';

import { t } from '../locale/texts';
import { type Vertical, type VerticalConfig, verticalConfig } from '../search/common/config';

import { IconAirplane42, IconAirplaneHotel42, IconCabin42, IconCarRent42, IconHotel42 } from '@fabric-ds/icons/react';

import styles from './Menu.module.css';

export type MenuProps = {
    activeVertical?: string;
    setVertical?: (event: MouseEvent<HTMLButtonElement>, vertical: Vertical) => void;
    unleash?: Record<string, string | boolean>;
    widget?: string;
};

export const Menu = ({ activeVertical, setVertical, unleash, widget }: MenuProps) => {
    const renderMenuItem = (config: VerticalConfig, title: string, icon: ReactElement) => {
        const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
            if (setVertical) {
                setVertical(event, config.vertical);
            } else {
                window.location.assign(config.fullPath);
            }
        };

        return (
            <Tab
                name={config.vertical}
                label={title}
                over
                onClick={handleClick}
                data-testid={`tab-${config.vertical}`}
                className={styles.navItemText}>
                {icon}
            </Tab>
        );
    };

    const isCarSale = !widget && !!unleash?.carRentalSale;
    const dynamicCarIcon = (
        <div>
            <IconCarRent42 aria-hidden="true" />
            {isCarSale && <div className={styles.carSale} data-testid="car-sale" />}
        </div>
    );

    return (
        <Tabs active={activeVertical ?? 'none-active'} className={`${styles.travelMenuTabs} mt-8`} data-testid="travel-menu">
            {renderMenuItem(verticalConfig.flight, t('verticals.flight'), <IconAirplane42 aria-hidden="true" />)}
            {renderMenuItem(verticalConfig.hotel, t('verticals.hotel'), <IconHotel42 aria-hidden="true" />)}
            {renderMenuItem(verticalConfig.carrental, t('verticals.car'), dynamicCarIcon)}
            {renderMenuItem(verticalConfig.prepackage, t('verticals.prepackage_dynamic'), <IconAirplaneHotel42 aria-hidden="true" />)}
            {renderMenuItem(verticalConfig.fhh, t('verticals.fhh_short'), <IconCabin42 aria-hidden="true" />)}
        </Tabs>
    );
};
