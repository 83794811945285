import { validSeoPath } from '../util/formatting';
import {
    AfricaIcon,
    AsiaIcon,
    EuropeIcon,
    SouthAmericaIcon,
    NorthAmericaIcon,
    OceaniaIcon,
    NorthEuropeIcon,
    NorwaySummerIcon,
    NorwayVacationIcon,
    SydenIcon,
    PopularIcon,
    IslandsIcon,
    EastEuropeIcon,
    ExoticIcon,
    HistoricIcon,
} from './SeoIcons';
import type { SeoLink, SeoLinkDestination } from './interfaces';

const icons = {
    europe: EuropeIcon,
    europeNorth: NorthEuropeIcon,
    europeEast: EastEuropeIcon,
    africa: AfricaIcon,
    asia: AsiaIcon,
    americaNorth: NorthAmericaIcon,
    americaSouth: SouthAmericaIcon,
    oceania: OceaniaIcon,
    popular: PopularIcon,
    syden: SydenIcon,
    islands: IslandsIcon,
    exotic: ExoticIcon,
    historic: HistoricIcon,
    norwayCity: NorwayVacationIcon,
    norwaySummer: NorwaySummerIcon,
};

type LinkTreeProps = {
    heading: string;
    description: string;
    data: SeoLink[];
    url: string;
};

export const LinkTree = ({ heading, description, data, url }: LinkTreeProps) => {
    const renderTable = (cluster: SeoLink) => (
        <div className="place-content-center" key={`dlt${cluster.icon}`}>
            {(icons[cluster.icon] ?? PopularIcon)()}
            <table className="w-full text-left mt-12">
                <thead>
                    <tr className="bg-bluegray-50">
                        <th className="px-16 py-8 border-b">{cluster.title}</th>
                    </tr>
                </thead>
                <tbody>
                    {cluster.destinations.map((destination: SeoLinkDestination) => (
                        <tr key={`dlt${destination.city ?? destination.country}`}>
                            <td className="px-16 py-8 border-b">
                                <a
                                    href={`${url}${validSeoPath(destination.country)}/${
                                        destination.city ? `${validSeoPath(destination.city)}/` : ''
                                    }`}
                                    className="text-gray-700 block">
                                    {destination.city ?? destination.country}
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <section className="mb-64" aria-labelledby="seo-destinationlinktree-title">
            <h2 id="seo-destinationlinktree-title" className="text-center mx-16">
                {heading}
            </h2>
            <div className="pb-16 text-center px-32 sm:px-64">{description}</div>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-16">{data.map(renderTable)}</div>
        </section>
    );
};
