import pulse from '@finn-no/pulse-sdk';
import { type Vertical, verticalConfig } from './config';

type Target = 'Listing' | 'Form';

// TR_ev01 - TR_ev09 (see tracking section in README.md)
export const trackSearchClick = (name, url, vertical, position, item, targetType: Target = 'Listing'): void => {
    pulse.trackEvent({
        type: 'Click',
        intent: 'Search',
        name: name,
        object: {
            // @ts-ignore id can only be string
            id: undefined, // set by Pulse SDK
            type: 'UIElement',
            elementType: 'Button',
            position,
            items: [item],
        },
        target: {
            id: url,
            type: targetType,
        },
        vertical: {
            name: 'travel',
            subVertical: vertical,
        },
    });
};

// Pulse tracker id: TR_pv01 - TR_pv05 (see tracking section in README.md)
export const trackBasicPageView = (vertical: Vertical): void => {
    let pulseVertical = '';
    let url = '';

    switch (vertical) {
        case verticalConfig.flight.vertical:
            pulseVertical = 'flight';
            url = verticalConfig.flight.fullPath;
            break;
        case verticalConfig.hotel.vertical:
            pulseVertical = 'hotel';
            url = verticalConfig.hotel.fullPath;
            break;
        case verticalConfig.carrental.vertical:
            pulseVertical = 'car_rental';
            url = verticalConfig.carrental.fullPath;
            break;
        case verticalConfig.prepackage.vertical:
            pulseVertical = 'prepackage';
            url = verticalConfig.prepackage.fullPath;
            break;
        case verticalConfig.fhh.vertical:
            pulseVertical = 'holiday_rental';
            url = verticalConfig.fhh.fullPath;
            break;
    }

    pulse.trackPageView({
        object: {
            type: 'MarketFrontpage',
            name: pulseVertical,
        },
        vertical: {
            name: 'travel',
            subVertical: pulseVertical,
        },
    });
};
