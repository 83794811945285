export const hotelDefaultState = {
    destination: '',
    destinationContext: null,
    startDate: null,
    endDate: null,
    rooms: [
        {
            adults: 2,
            children: [],
        },
    ],
};
