import { makeAction, makeReducer } from '@finn-no/redux-actions';

export const toggleMonth = (months, month, year) => {
    const copy = [...months];
    const index = copy.findIndex((e) => e.month === month && e.year === year);
    if (index >= 0) {
        copy.splice(index, 1);
    } else {
        copy.push({ month, year });
    }
    return copy;
};

const actions = {};

actions.allFieldsChanged = makeAction('PREPACKAGE/ALL_FIELDS_CHANGED', (state, { payload }) => ({ ...state, ...payload }));

actions.setSearchType = makeAction('PREPACKAGE/SET_SEARCH_TYPE', (state, { payload: searchType }) => ({
    ...state,
    searchType: searchType || 'fixed',
}));

actions.setOriginAirport = makeAction('PREPACKAGE/SET_ORIGIN_AIRPORT', (state, { payload: originAirport }) => ({
    ...state,
    originAirport: originAirport || '',
}));

actions.setDestination = makeAction('PREPACKAGE/SET_DESTINATION', (state, { payload: destination }) => ({
    ...state,
    destinationId: destination ? destination.id : undefined,
    destination: destination ? destination.text : '',
}));

actions.setDates = makeAction('PREPACKAGE/SET_DATES', (state, { payload: newDates }) => ({
    ...state,
    departureDate: newDates.startDate ?? null,
    returnDate: newDates.endDate ?? null,
}));

actions.setFlexibleDeparture = makeAction('PREPACKAGE/SET_FLEXIBLE_DEPARTURE', (state, { payload: flexibleDeparture }) => ({
    ...state,
    flexibleDeparture: Number.parseInt(flexibleDeparture) || 0,
}));

actions.toggleTravelMonth = makeAction('PREPACKAGE/TOGGLE_TRAVEL_MONTH', (state, { payload: month }) => ({
    ...state,
    travelMonths: toggleMonth(state.travelMonths, month.month, month.year),
}));

actions.resetTravelMonths = makeAction('PREPACKAGE/RESET_TRAVEL_MONTHS', (state) => ({
    ...state,
    travelMonths: [],
}));

actions.setDuration = makeAction('PREPACKAGE/SET_DURATION', (state, { payload: duration }) => ({
    ...state,
    duration: {
        from: duration.from || 0,
        to: duration.to || 35,
    },
}));

actions.getFacetsStarted = makeAction('PREPACKAGE/GET_FACETS_STARTED', (state, { payload: params }) => ({
    ...state,
    facetData: {
        ...state.facetData,
        [params]: false,
    },
}));

actions.getFacetsSuccess = makeAction('PREPACKAGE/GET_FACETS_SUCCESS', (state, { payload: { params, data } }) => {
    const reducer = (accumulator, currentValue) => {
        accumulator[currentValue.value] = currentValue.count;
        return accumulator;
    };

    return {
        ...state,
        facetData: {
            ...state.facetData,
            [params]: data.location.reduce(reducer, {}),
        },
    };
});

actions.getFacetsFailure = makeAction('PREPACKAGE/GET_FACETS_FAILURE', (state, { payload: params }) => ({
    ...state,
    facetData: {
        ...state.facetData,
        [params]: false,
    },
}));

actions.showCalendar = makeAction('CALENDAR/SHOW_CALENDAR', (state, { payload: { calendarId } }) => ({ ...state, calendar: calendarId }));

export default actions;
export const reducer = makeReducer(actions);
