export const carDefaultState = {
    pickupLocation: '',
    pickupLocationId: null,
    pickupLocationLatitude: null,
    pickupLocationLongitude: null,
    pickupDate: null,
    pickupTime: '10:00',

    returnLocationIsSame: true,
    returnLocation: '',
    returnLocationId: null,
    returnLocationLatitude: null,
    returnLocationLongitude: null,
    returnDate: null,
    returnTime: '10:00',

    driverAgeIsStandard: true,
    driverAge: '24',
};
