import { Button } from '@fabric-ds/react';

import { t } from '../../locale/texts';
import { flightAutocompleteMapper } from '../common/autocompleteMapper';

import { SwapIcon } from '../../icons/Swap';
import { Autocomplete, type AutocompleteOption } from '../common/Autocomplete';

export type Props = {
    id: string;
    originName?: string | null;
    originError?: string;
    originOnSelect: (opt: AutocompleteOption | null) => void;
    destinationName?: string | null;
    destinationError?: string;
    destinationOnSelect: (opt: AutocompleteOption | null) => void;
    onSwap: () => void;
    url: string;
    className?: string;
};

export const AutocompleteSwap = ({
    id,
    originName,
    originError,
    originOnSelect,
    destinationName,
    destinationError,
    destinationOnSelect,
    onSwap,
    url,
    className,
}: Props) => (
    <div className={`flex gap-x-8 sm:col-span-2 ${className ?? ''}`}>
        <Autocomplete
            id={`origin-${id}`}
            label={t('flight.autocomplete.from.label')}
            placeholder={t('flight.autocomplete.from.placeholder')}
            defaultValue={originName}
            url={url}
            mapOptions={flightAutocompleteMapper}
            error={originError}
            onSelect={originOnSelect}
        />
        <Button
            utility
            onClick={onSwap}
            aria-label={t('flight.autocomplete.swap')}
            className="pl-8 pr-8 max-h-48"
            style={{ marginTop: '22px' }}>
            <SwapIcon />
        </Button>
        <Autocomplete
            id={`destination-${id}`}
            label={t('flight.autocomplete.to.label')}
            placeholder={t('flight.autocomplete.to.placeholder')}
            defaultValue={destinationName}
            error={destinationError}
            url={url}
            mapOptions={flightAutocompleteMapper}
            onSelect={destinationOnSelect}
        />
    </div>
);
