import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { ErrorBoundary } from 'shared/util/ErrorBoundary';
import type { VerticalConfig } from '@/config/verticalConfig';
import type { SspProps } from '@/util/verticalSsp';
import { Front } from 'shared/search/Front';
import { trackBasicPageView } from 'shared/search/common/tracking';
import { FrontPage } from '@/components/FrontPage';

type Props = {
    config: VerticalConfig;
};

export const Verticals = ({ config, cmsPodletContent, loadedVertical, unleash, widgetId }: Props & SspProps) => {
    const router = useRouter();

    useEffect(() => {
        const trackRouteChange = () => {
            trackBasicPageView(config.vertical);
        };

        router.events.on('routeChangeComplete', trackRouteChange);

        return () => {
            router.events.off('routeChangeComplete', trackRouteChange);
        };
    }, [router]);

    const pushUrlPath = (v, fullPath) => router.push(fullPath.replace('/reise', ''), undefined, { shallow: true });

    return (
        <ErrorBoundary key={`${config.vertical}SearchErrorBoundary`}>
            <FrontPage loadedVertical={loadedVertical} cmsPodletContent={cmsPodletContent} widgetId={widgetId} unleash={unleash}>
                <Front vertical={config.vertical} unleash={unleash} widget={widgetId} pushUrlPath={widgetId ? undefined : pushUrlPath} />
            </FrontPage>
        </ErrorBoundary>
    );
};
