import { t } from '../../locale/texts';
import { PlusIcon } from '../../icons/PlusIcon';
import { MinusIcon } from '../../icons/MinusIcon';

export type PassengersProps = {
    adults: number;
    childrenCount: number;
    infants?: number;
    showInfants?: boolean;
    setPassenger: (value: Record<string, number>) => void;
    index?: number;
    minAdults?: number;
    maxAdults?: number;
    minChildren?: number;
    maxChildren?: number;
    minInfants?: number;
    maxInfants?: number;
};

const trans = (key: string) => t(`flight.passengers.inputs.${key}`);

export const Passengers = ({
    adults,
    minAdults = 1,
    maxAdults = 9,
    minChildren = 0,
    maxChildren = 8,
    minInfants = 0,
    maxInfants = 8,
    childrenCount,
    infants,
    showInfants,
    setPassenger,
    index,
}: PassengersProps) => {
    const isMaxPassengers = adults + childrenCount + (infants ?? 0) >= 9;
    const isMaxAdults = isMaxPassengers || adults >= maxAdults;
    const isMaxChildren = isMaxPassengers || childrenCount >= maxChildren;
    const isMaxInfants = isMaxPassengers || (infants ?? 0) >= maxInfants;
    const indexPostfix = typeof index === 'undefined' ? '' : `-${index}`;

    return (
        <div className="grid grid-cols-5 gap-4 place-items-center">
            <div className="col-span-2 justify-self-start text-14">
                {trans('adultLabel')}
                {showInfants ? <div className="text-12">{trans('adultShortDescription')}</div> : null}
            </div>
            <button
                type="button"
                onClick={() => setPassenger({ adults: adults - 1 })}
                aria-label={trans('removeAdult')}
                disabled={adults === minAdults}
                tabIndex={-1}
                data-testid={`remove-adult${indexPostfix}`}>
                <MinusIcon width={28} height={28} disabled={adults === 1} />
            </button>
            <input
                aria-label={trans('adultLabel')}
                type="number"
                className="text-center"
                data-testid={`adults-count${indexPostfix}`}
                onFocus={(event) => event.target.select()}
                onChange={({ target: { value } }) => setPassenger({ adults: value === '' ? 1 : Number(value) })}
                value={adults || 1}
                min={minAdults}
                max={maxAdults}
                style={{ paddingLeft: 2, paddingRight: 2 }}
            />
            <button
                type="button"
                onClick={() => setPassenger({ adults: adults + 1 })}
                aria-label={trans('addAdult')}
                tabIndex={-1}
                disabled={isMaxAdults}
                data-testid={`add-adult${indexPostfix}`}>
                <PlusIcon width={28} height={28} disabled={isMaxAdults} />
            </button>

            <div className="col-span-2 justify-self-start text-14">
                {trans('childLabel')}
                {showInfants ? <div className="text-12">{trans('childDescription')}</div> : null}
            </div>
            <button
                type="button"
                onClick={() => setPassenger({ children: childrenCount - 1 })}
                aria-label={trans('removeChild')}
                disabled={childrenCount === minChildren}
                tabIndex={-1}
                data-testid={`remove-child${indexPostfix}`}>
                <MinusIcon width={28} height={28} disabled={childrenCount === 0} />
            </button>
            <input
                aria-label={trans('childLabel')}
                type="number"
                className="text-center"
                data-testid={`children-count${indexPostfix}`}
                onChange={({ target: { value } }) => setPassenger({ children: value === '' ? 0 : Number(value) })}
                onFocus={(event) => event.target.select()}
                value={childrenCount}
                min={minChildren}
                max={maxChildren}
                style={{ paddingLeft: 2, paddingRight: 2 }}
            />
            <button
                type="button"
                onClick={() => setPassenger({ children: childrenCount + 1 })}
                aria-label={trans('addChild')}
                tabIndex={-1}
                disabled={isMaxChildren}
                data-testid={`add-child${indexPostfix}`}>
                <PlusIcon width={28} height={28} disabled={isMaxChildren} />
            </button>
            {showInfants ? (
                <>
                    <div className="col-span-2 justify-self-start text-14">
                        {trans('infantLabel')}
                        <div className="text-12">{trans('infantDescription')}</div>
                    </div>
                    <button
                        type="button"
                        onClick={() => setPassenger({ infants: (infants ?? 1) - 1 })}
                        aria-label={trans('removeInfant')}
                        disabled={infants === minInfants}
                        tabIndex={-1}
                        data-testid={`remove-infant${indexPostfix}`}>
                        <MinusIcon width={28} height={28} disabled={infants === 0} />
                    </button>
                    <input
                        aria-label={trans('infantLabel')}
                        type="number"
                        className="text-center"
                        data-testid={`infant-count${indexPostfix}`}
                        onChange={({ target: { value } }) => setPassenger({ infants: value === '' ? 0 : Number(value) })}
                        onFocus={(event) => event.target.select()}
                        value={infants}
                        min={minInfants}
                        max={maxInfants}
                        style={{ paddingLeft: 2, paddingRight: 2 }}
                    />
                    <button
                        type="button"
                        onClick={() => setPassenger({ infants: (infants ?? 0) + 1 })}
                        aria-label={trans('addInfant')}
                        tabIndex={-1}
                        disabled={isMaxInfants}
                        data-testid={`add-infant${indexPostfix}`}>
                        <PlusIcon width={28} height={28} disabled={isMaxInfants} />
                    </button>
                </>
            ) : null}
            {isMaxPassengers ? (
                <div className="col-span-5 my-16 text-14 text-red-700" data-testid="passenger-count-warning">
                    {trans('countWarning')}
                </div>
            ) : null}
        </div>
    );
};
