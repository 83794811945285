import { addDays, safeFormatClientDate } from '../../util/datetime-fns';
import { queryString } from '../../util/query';

export const flightResultUrl = ({ state, filters = {}, url = '/reise/flybilletter/resultat', widget }): string => {
    const params = {
        tripType: state.tripType,
        requestedOrigin: state.origin,
        requestedDestination: state.destination,
        requestedOrigin2: state.tripType === 'openjaw' ? state.origin2 : null,
        requestedDestination2: state.tripType === 'openjaw' ? state.destination2 : null,
        requestedDepartureDate: safeFormatClientDate(state.departureDate),
        requestedReturnDate: state.tripType !== 'oneway' ? safeFormatClientDate(state.returnDate) : null,
        adults: state.adults,
        children: state.children || null,
        infants: state.infants || null,
        cabinType: state.cabinType,
        ...filters,
    };
    const widgetParams = widget ? `&utm_source=${widget}&utm_medium=widget&utm_campaign=fly` : '';

    return `${url}${queryString(params)}${widgetParams}`;
};

export const flightHotelUrl = (state): string => {
    const baseUrl = `/reise/hotell/flyplass/${state.destination.substring(0, 3)}/`;
    const children = state.children + state.infants;
    const ages =
        children > 0
            ? Array.from(Array(children))
                  .map(() => 8)
                  .join('.')
            : '';
    const params = {
        checkInDate: safeFormatClientDate(state.departureDate),
        checkOutDate:
            state.tripType === 'oneway' ? safeFormatClientDate(addDays(1, state.departureDate)) : safeFormatClientDate(state.returnDate),
        rooms: `${state.adults}${ages ? `-${ages}` : ''}`,
        source: 'ft',
    };
    return `${baseUrl}${queryString(params)}`;
};

export const mapFlightSearchToPath = ({
    tripType,
    requestedOrigin,
    requestedDestination,
    requestedOrigin2,
    requestedDestination2,
    requestedDepartureDate,
    requestedReturnDate,
    adults,
    children,
    infants,
    cabinType,
    ...rest
}: Record<string, string>): string => {
    const filterJoin = (data: (string | undefined)[]) => data.filter((od) => !!od).join('-');

    const ods = filterJoin([
        requestedOrigin.substring(0, requestedOrigin.includes('.MET') ? 5 : 3),
        requestedDestination.substring(0, requestedDestination.includes('.MET') ? 5 : 3),
        requestedOrigin2?.substring(0, requestedOrigin2.includes('.MET') ? 5 : 3),
        requestedDestination2?.substring(0, requestedDestination2.includes('.MET') ? 5 : 3),
    ]);
    const dates = filterJoin([requestedDepartureDate, tripType !== 'oneway' ? requestedReturnDate : undefined]);
    const passengers = filterJoin([adults, children, infants]);
    const cabins = cabinType ? `/${cabinType.split(',').length === 4 ? 'all' : cabinType.split(',').join('-')}` : '';

    return `/${ods}/${dates}/${passengers}${cabins}/${queryString(rest)}`;
};
