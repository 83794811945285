import { type FormEvent, useEffect } from 'react';

import { t } from '../../locale/texts';
import { safeFormatClientDate } from '../../util/datetime-fns';
import { addGclidToLocalStorage, addLabelToLocalStorage } from './referral';
import type { HotelRoom } from './HotelRooms';

const roomsToQueryParams = (rooms?: HotelRoom[]) =>
    rooms && rooms.length > 0
        ? rooms.map((room) => (room.children.length > 0 ? `${room.adults}-${room.children.join('.')}` : `${room.adults}`))
        : undefined;

const submitUrl = (resultUrl, destination) => {
    if (destination?.themePath) {
        return `${resultUrl}/${destination.themePath}/`;
    }
    if (destination?.type === 'hotel') {
        return `${resultUrl}/detaljer/${destination?.key}/`;
    }
    return `${resultUrl}/resultat/${destination?.key}/${isNumericString(destination?.key) ? fakeNamedDestinationPathFromText(destination.text) : ''}`;
};

const isNumericString = (value: string): boolean => {
    return /^\d+$/.test(value);
};

// this is to show a destination name in the url, but it is not actually used for anything...
const fakeNamedDestinationPathFromText = (text: string): string => {
    const destination = text
        .split(',')[0]
        .toLowerCase()
        .replace(/æ/g, 'ae')
        .replace(/ø/g, 'o')
        .replace(/å/g, 'a')
        .replace(/[^\w\-]+/g, '_');
    return `${destination}/`;
};

type HotelSearchSubmitProps = {
    resultUrl: string;
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
    rooms?: HotelRoom[];
    startDate?: Date;
    endDate?: Date;
    // biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
    destinationContext?: Record<string, any>;
    widget?: string;
    position?: string;
};

// biome-ignore lint/suspicious/noExplicitAny: This was set before biome was added
const placeNameFromKey = (destinationContext?: Record<string, any>): string =>
    destinationContext?.placeKey?.charAt(0).toUpperCase() + destinationContext?.placeKey?.slice(1);

export const HotelSearchSubmit = ({
    resultUrl,
    handleSubmit,
    destinationContext,
    startDate,
    endDate,
    rooms,
    widget,
    position,
}: HotelSearchSubmitProps) => {
    useEffect(() => {
        addGclidToLocalStorage();
        addLabelToLocalStorage();
    }, []);

    const checkInDate = safeFormatClientDate(startDate);
    const checkOutDate = safeFormatClientDate(endDate);
    const showDate = destinationContext?.searchable && checkInDate && checkOutDate;
    const displayDestination = destinationContext?.text ?? placeNameFromKey(destinationContext);

    return (
        <form onSubmit={handleSubmit} action={submitUrl(resultUrl, destinationContext)} method="GET" data-testid="hotelSearchForm">
            {showDate ? (
                <>
                    <input data-testid="checkInDate" type="hidden" name="checkInDate" value={checkInDate} />
                    <input data-testid="checkOutDate" type="hidden" name="checkOutDate" value={checkOutDate} />
                </>
            ) : null}
            {destinationContext?.searchable &&
                (roomsToQueryParams(rooms) || []).map((room, index) => (
                    // biome-ignore lint/suspicious/noArrayIndexKey: This was set before biome was added
                    <input key={`room-${index}`} data-testid={`room-${index}`} type="hidden" name="rooms" value={room} />
                ))}

            {widget && (
                <>
                    <input type="hidden" name="utm_source" value={widget} />
                    <input type="hidden" name="utm_medium" value="widget" />
                    <input type="hidden" name="utm_campaign" value="hotell" />
                </>
            )}

            <button
                type="submit"
                className="button button--primary px-64 w-full max-w-full sm:w-auto sm:max-w-auto mt-24"
                data-testid="hotelSearchButton">
                {t('action.search')}
            </button>
            {position === 'destinationpages' && destinationContext && (
                <strong>
                    <a
                        href={`${submitUrl(resultUrl, destinationContext)}?rooms=2&sort=popularity&sortDirection=descending`}
                        className="mt-24 mr-4 float-right">
                        Se alle hotell i {displayDestination}
                    </a>
                </strong>
            )}
        </form>
    );
};
