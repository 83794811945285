import type { Vertical } from '../search/common/config';
import { PopularCities } from './PopularCities';
import { PopularCountries } from './PopularCountries';
import { LinkTree } from './LinkTree';

import flightData from './data/flight.json';
import hotelData from './data/hotel.json';
import carData from './data/carrental.json';
import ppData from './data/pp.json';
import fhhData from './data/fhh.json';

const dataMap = {
    flight: flightData,
    hotel: hotelData,
    carrental: carData,
    prepackage: ppData,
    fhh: fhhData,
};

type FrontpageSeoProps = {
    vertical: Vertical;
};

export const FrontpageSeo = ({ vertical }: FrontpageSeoProps) => {
    const data = dataMap[vertical];

    return (
        <>
            <PopularCities cities={data.defaultCities} cdnUrl={data.cdnPath} verticalUrl={data.basePath} lang={data.lang} />
            <PopularCountries countries={data.defaultCountries} cdnUrl={data.cdnPath} verticalUrl={data.basePath} lang={data.lang} />
            <LinkTree
                heading={data.lang.linkTree.heading}
                description={data.lang.linkTree.description}
                data={data.linkTree}
                url={data.basePath}
            />
        </>
    );
};
