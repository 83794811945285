import { PopularCountriesCard } from './PopularCountriesCard';
import type { SeoDestination, SeoText } from './interfaces';

type PopularCountriesProps = {
    destination?: SeoDestination;
    countries: SeoDestination[];
    cdnUrl: string;
    verticalUrl: string;
    lang: SeoText;
};

export const PopularCountries = ({ destination, countries, cdnUrl, verticalUrl, lang }: PopularCountriesProps) => {
    const renderPopularCountriesCard = (dest, idx: number) => (
        <PopularCountriesCard
            destination={dest}
            key={`countryCard${idx}`}
            id={`popCountry-${idx}`}
            cdnUrl={cdnUrl}
            verticalUrl={verticalUrl}
            lang={lang}
        />
    );

    const headingText = (): string =>
        destination
            ? lang.popularCountries.headingOther + (destination.continent ? ` i ${destination.continent}` : '')
            : lang.popularCountries.headingDefault;

    const descriptionText = (): string =>
        destination
            ? lang.popularCountries.descriptionOther
                  .replace(/\$continent/gm, destination.continent ?? '')
                  .replace(/\$destination/gm, destination.city ?? destination.country)
            : lang.popularCountries.descriptionDefault;

    return countries?.length > 1 ? (
        <section className="mb-64" aria-labelledby="seo-popular-country-title">
            <h2 id="seo-popular-country-title" className="text-center mx-16" data-testid="destination-countries-heading">
                {headingText()}
            </h2>
            <div className="mb-16 text-center px-32 sm:px-64" data-testid="destination-countries-description">
                {descriptionText()}
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3">{countries.map(renderPopularCountriesCard)}</div>
        </section>
    ) : null;
};
