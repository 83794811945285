import carrentalFaq from 'shared/seo/faq/carrentalFaq.json';
import fhhFaq from 'shared/seo/faq/fhhFaq.json';
import flightFaq from 'shared/seo/faq/flightFaq.json';
import hotelFaq from 'shared/seo/faq/hotelFaq.json';
import prepackageFaq from 'shared/seo/faq/prepackageFaq.json';

const faq = {
    carrental: carrentalFaq,
    fhh: fhhFaq,
    flight: flightFaq,
    hotel: hotelFaq,
    prepackage: prepackageFaq,
};

export const googleFaq = (vertical) => (
    <script type="application/ld+json" data-cy="faq-content" dangerouslySetInnerHTML={{ __html: JSON.stringify(faq[vertical]) }} />
);
